import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import {
  FlipFoodDataTableRow,
  FlipFoodSessionData,
} from "types/games/flipFoodGameTypes";
import GameDetailLayout from "../GameDetailLayout";

function FlipFoodDetail() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "totalHand", label: t("game.totalHand") },
    { key: "score", label: t("game.score") },
    { key: "countOfFlip", label: t("game.numberOfFlip") },
    { key: "countOfBurnt", label: t("game.countOfBurnt") },
  ];

  const convertDataToDataTable = (
    _data: FlipFoodSessionData[]
  ): FlipFoodDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        totalHand: session.totalHand,
        score: session.score,
        countOfFlip: `${session.countOfFlip} / ${session.numberOfFlip}`,
        countOfBurnt: session.countOfBurnt,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default FlipFoodDetail;
