import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import fs from "fs";

// Lấy thông tin S3 từ biến môi trường
const accessKeyId =
  process.env.REACT_APP_AWS_ACCESS_KEY_ID || "AKIA5U6YKVAHXAQHFZVY";
const secretAccessKey =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ||
  "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";
const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME || "mc-mi01-games-dev";
const region = process.env.REACT_APP_AWS_REGION || "ap-northeast-1"; // Mặc định là 'us-west-2'

// Cấu hình S3 Client với thông tin từ biến môi trường
const s3 = new S3Client({
  region,
  credentials: {
    accessKeyId,
    secretAccessKey,
  },
});

const downloadParams = {
  Bucket: bucketName, // Tên bucket của bạn
  Key: "flip_food/handtracking.js", // Đường dẫn và tên file trong S3 bucket
};

export const downloadFileFromS3 = async () => {
  try {
    // Gửi yêu cầu lấy file từ S3
    const data = await s3.send(new GetObjectCommand(downloadParams));

    // Kiểm tra nếu data.Body có tồn tại
    if (data.Body) {
      // Lưu file vào hệ thống tệp (sử dụng đường dẫn tệp trực tiếp)
      const writeStream = fs.createWriteStream("./handtracking.js"); // Sử dụng đường dẫn trực tiếp thay vì path.join

      // Sử dụng stream để lưu dữ liệu từ S3 vào file
      (data.Body as NodeJS.ReadableStream).pipe(writeStream);

      writeStream.on("finish", () => {
        console.log("File downloaded successfully!");
      });
    } else {
      console.error("No data found in the body.");
    }
  } catch (err) {
    console.error("Error downloading file:", err);
  }
};
