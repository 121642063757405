import { TooltipItem } from "chart.js";
import {
  ADD_SUBTRACT,
  HAND_SHAPER,
  JIGSAW_PUZZLE,
  LINE_WORKER,
  MEMORY_CARD,
  TILT_THROW,
  TMT_A,
  TMT_B,
} from "constants/gameIds";

export const GET_CHART_CONFIG_BY_GAME_ID = {
  [TMT_A]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index + 1}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => Number(item["Score"])),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "TMT_A Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [
                `Spent Time: ${data["Spent Time"] as string}`,
                `Incorrections: ${data["Incorrections"] as string}`,
                `Completion Points: ${data["Completion Points"] as string}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
  [TMT_B]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index + 1}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => Number(item["Score"])),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "TMT_B Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [
                `Spent Time: ${data["Spent Time"] as string}`,
                `Incorrections: ${data["Incorrections"] as string}`,
                `Completion Points: ${data["Completion Points"] as string}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
  [LINE_WORKER]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Left Average Angle",
          data: datasets.map((item) => Number(item["Left Average Angle"])),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
          spanGaps: true,
        },
        {
          label: "Right Average Angle",
          data: datasets.map((item) => Number(item["Right Average Angle"])),
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
          pointRadius: 6,
          spanGaps: true,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Line Worker Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [
                `Left Average Angle: ${data["Left Average Angle"] as string}`,
                `Right Average Angle: ${data["Right Average Angle"] as string}`,
                `Spent Time: ${data["Spent Time"] as string}`,
                `Repetitions: ${data["Repetitions"] as string}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 40,
          beginAtZero: true,
          title: {
            display: true,
            text: "Average Angle",
          },
        },
      },
    },
  }),
  [TILT_THROW]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Average Up Angle",
          data: datasets.map((item) => Number(item["Up Average Angle"])),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
          spanGaps: true,
        },
        {
          label: "Average Down Angle",
          data: datasets.map((item) => Number(item["Down Average Angle"])),
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
          pointRadius: 6,
          spanGaps: true,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Head Up and Down Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [
                `Spent Time: ${data["Spent Time"]}`,
                `Up Repetitions: ${data["Up Repetitions"] || "Empty"}`,
                `Up Average Repetitions: ${
                  data["Up Average Repetitions"] || "Empty"
                }`,
                `Down Repetitions: ${data["Down Repetitions"] || "Empty"}`,
                `Down Average Repetitions: ${
                  data["Down Average Repetitions"] || "Empty"
                }`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 40,
          beginAtZero: true,
          title: {
            display: true,
            text: "Average Angle",
          },
        },
      },
    },
  }),
  [ADD_SUBTRACT]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => item.Score),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Add & Subtract Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [
                `Accuracy: ${data["Accuracy"] as string}`,
                `Completed Questions: ${data["Completed Questions"] as string}`,
                `Average Time: ${data["Average Time"] as string}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
  [HAND_SHAPER]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => item.Score),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Hand Shaper Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [`Score: ${data["Score"] as string}`];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
  [MEMORY_CARD]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => item.Score),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Memory Card Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [`Score: ${data["Score"] as string}`];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
  [JIGSAW_PUZZLE]: (labels: string[], datasets: Record<string, unknown>[]) => ({
    type: "line",
    data: {
      labels: labels || datasets.map((item, index) => `Session ${index}`),
      datasets: [
        {
          label: "Score",
          data: datasets.map((item) => item.Score),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          pointRadius: 6,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Puzzle Session Chart",
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value: number | string) => value,
          color: "black",
          font: {
            weight: "bold",
          },
        },
        tooltip: {
          callbacks: {
            afterBody: function (context: TooltipItem<"line">[]) {
              const dataIndex = context[0].dataIndex;
              const data = datasets[dataIndex];
              return [`Score: ${data["Score"] as string}`];
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 11,
          beginAtZero: true,
          title: {
            display: true,
            text: "Score",
          },
        },
      },
    },
  }),
};
